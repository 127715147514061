<template>
    <v-row class="filters-padding">
        <v-col cols="2">
            <TextField
                v-model="filters.Name"
                :disableMessage="true"
                :label="$t('db.name')"
                :clearable="true"
            ></TextField>
        </v-col>
        <v-spacer />
        <v-col cols="auto">
            <v-btn
                class="button-bg-secondary"
                :text="$t('db.showAllProspects')"
                prepend-icon="mdi-database-outline"
                @click="$router.push({ name: 'allProspects' })"
            />
        </v-col>
    </v-row>
    <v-row justify="end" class="pr-12">
        <v-col cols="auto" class="pb-0 pt-0">
            <v-row no-gutters>
                <div class="flex-container">
                    <div>
                        <Span style="font-weight: bold"
                            >{{
                                countTotalProspects.totalProspects
                            }}&nbsp;</Span
                        >
                        <Span>{{ $t("db.prospects") }}</Span>
                    </div>
                    <Span class="pipe"> &nbsp;| </Span>
                    <div>
                        <Span style="font-weight: bold"
                            >{{ countTotalProspects.alreadyCalled }}&nbsp;</Span
                        >
                        <Span>{{ $t("db.alreadyCalled") }}</Span>
                    </div>
                    <Span class="pipe"> &nbsp;| </Span>
                    <div>
                        <Span style="font-weight: bold"
                            >{{ countTotalProspects.leftToCall }}&nbsp;</Span
                        >
                        <Span>{{ $t("db.leftToCall") }}</Span>
                    </div>
                    <Span class="pipe"> &nbsp;| </Span>
                    <div>
                        <Span style="font-weight: bold"
                            >{{ countTotalProspects.meetings }}&nbsp;</Span
                        >
                        <Span>{{ $t("db.meetings") }}</Span>
                    </div>
                </div>
            </v-row>
        </v-col>
    </v-row>
</template>

<script setup lang="ts">
import TextField from "@/components/customVuetify/TextField.vue";
import { onMounted, ref } from "vue";
import { useI18n } from "vue-i18n";

const { t: $t } = useI18n();

const emit = defineEmits(["applyFilters"]);

defineProps({
    countTotalProspects: {
        type: Object,
        required: true,
    },
});

const filters = ref({
    Name: "",
});

onMounted(() => {
    emit("applyFilters", filters.value);
});
</script>

<style scoped>
.flex-container {
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100%;
}

.flex-container > div {
    display: flex;
    align-items: center;
}

.pipe {
    padding-right: 8px;
}
</style>
