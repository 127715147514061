<template>
    <div class="container">
        <Title :title="$t('users.users')" />
        <Filters @addUser="createDialog = true" @applyFilters="applyFilters" />
        <List :users="filteredUsers" />
        <CreateUser v-model="createDialog" @close="createDialog = false" />
    </div>
</template>

<script setup lang="ts">
import { formatPhoneNumber } from "@/commons/convertion";
import { getUserFromId } from "@/commons/firebase";
import { getClientFromId } from "@/commons/firebase/clients";
import Title from "@/components/customVuetify/Title.vue";
import { db } from "@/main";
import { Roles, UserList } from "@/types";
import { collection, onSnapshot } from "firebase/firestore";
import { computed, onMounted, ref, watchEffect } from "vue";
import { useI18n } from "vue-i18n";
import CreateUser from "./CreateUser.vue";
import Filters from "./Filters.vue";
import List from "./List.vue";

const { t: $t } = useI18n();

const users = ref<UserList[]>([]);
const createDialog = ref(false);
const filters = ref({});

const filteredUsers = computed(() => {
    if (!filters.value || Object.keys(filters.value).length === 0) {
        return users.value;
    }
    return users.value.filter((user: any) =>
        Object.entries(filters.value).every(([key, value]) => {
            if (key === "id") return true;
            if (
                typeof value === "string" &&
                user[key] &&
                typeof user[key] === "string"
            ) {
                return user[key].toLowerCase().includes(value.toLowerCase());
            }
            if (
                Array.isArray(value) &&
                value.length > 0 &&
                user[key] &&
                typeof user[key] === "string"
            ) {
                return value.some(
                    (v) => v.toLowerCase() === user[key].toLowerCase()
                );
            }
            if (
                Array.isArray(value) &&
                value.length > 0 &&
                user[key] &&
                typeof user[key] === "number"
            ) {
                return value.some((v) => v === user[key]);
            }
            return true;
        })
    );
});

function applyFilters(newFilters: any) {
    filters.value = newFilters;
}

const getRoleName = (role: Roles) => {
    switch (role) {
        case 1:
            return $t("roles.admin");
        case 2:
            return $t("roles.supervisor");
        case 3:
            return $t("roles.operator");
        default:
            return "";
    }
};

onMounted(async () => {
    const userCollection = collection(db, "Users");
    const unsubscribe = onSnapshot(userCollection, async (snapshot) => {
        const userPromises = snapshot.docs.map(async (doc) => {
            const superiorData = doc.data().SuperiorId
                ? (await getUserFromId(doc.data().SuperiorId))?.data()
                : null;
            return {
                DocId: doc.data().DocId,
                LastName: doc.data().LastName.toUpperCase(),
                FirstName: doc.data().FirstName,
                Phone: doc.data().Phone
                    ? formatPhoneNumber(doc.data().Phone)
                    : "",
                Mail: doc.data().Mail,
                Role: getRoleName(doc.data().Role),
                ClientName: (await getClientFromId(doc.data().ClientId))?.Name,
                SuperiorName: superiorData
                    ? `${superiorData.FirstName} ${superiorData.LastName}`
                    : "",
            } as any;
        });
        users.value = await Promise.all(userPromises);
    });

    watchEffect((onInvalidate) => {
        onInvalidate(() => {
            unsubscribe();
        });
    });
});
</script>
